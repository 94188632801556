@import "./media.scss";

@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: local("Inter"), url("../assets/fonts/Inter-Thin.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: local("Inter"),
    url("../assets/fonts/Inter-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: local("Inter"), url("../assets/fonts/Inter-Light.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter"),
    url("../assets/fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: local("Inter"),
    url("../assets/fonts/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: local("Inter"),
    url("../assets/fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local("Inter"), url("../assets/fonts/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: local("Inter"),
    url("../assets/fonts/Inter-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: local("Inter"), url("../assets/fonts/Inter-Black.ttf") format("truetype");
}

/* font sizes map */
$ui-font-14: (
  null: (
    14px,
    1.22,
  ),
  $tablet: (
    14px,
    1.22,
  ),
  $small: (
    12px,
    1.22,
  ),
);
$ui-font-16: (
  null: (
    16px,
    1.22,
  ),
  $tablet: (
    16px,
    1.22,
  ),
  $small: (
    16px,
    1.22,
  ),
);
$ui-font-20: (
  null: (
    20px,
    1.22,
  ),
  $tablet: (
    22px,
    1.22,
  ),
  $small: (
    16px,
    1.22,
  ),
);
$ui-font-22: (
  null: (
    22px,
    1.22,
  ),
  $tablet: (
    22px,
    1.22,
  ),
  $small: (
    16px,
    1.22,
  ),
);
$ui-font-32: (
  null: (
    32px,
    1.22,
  ),
  $tablet: (
    32px,
    1.22,
  ),
  $small: (
    28px,
    1.22,
  ),
);
$ui-font-40: (
  null: (
    40px,
    1.22,
  ),
  $tablet: (
    40px,
    1.22,
  ),
  $small: (
    36px,
    1.22,
  ),
);
$ui-font-64: (
  null: (
    64px,
    1.22,
  ),
  $tablet: (
    64px,
    1.22,
  ),
  $small: (
    36px,
    1.22,
  ),
);

/* FONT SIZE SCALE MIXIN */

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    } @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (max-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  } @else {
    font-size: $fs-font-size;
  }
}
