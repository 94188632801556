@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.header-profile {
  position: relative;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;

  .header-profile-name {
    position: relative;
    width: 78px;
    height: 20px;

    span {
      width: 78px;
      color: $ui-text-light;
      font-size: 16px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: all 0.35s ease-out 0s;
    }
  }

  .header-profile-avatar-full {
    width: 35px;
    height: 35px;
    margin: 0 12px;
  }
}

/* mouse devices */
@media (pointer: fine) {
  .header-profile {
    position: relative;

    .header-profile-button {
      position: relative;
    }

    &:hover {
      cursor: pointer;
      
      .header-profile-button {
        background-color: #495a6d;
        transition: all 0.35s ease-out 0s;
      }

      .header-profile-name {
        span {
          position: absolute;
          top: 0;
          right: 0;
          width: auto;
          background-color: $ui-bg-light;
          color: $ui-text-dark;
          transition: all 0.35s ease-out 0s;
        }
      }
    }
  }
}
