@import "../../styles/colors.scss";

.svg-icon {
  flex-grow: 0;
  flex-shrink: 0;

  &.left {
    margin-left: 12px;
  }
  
  /* colors */
  &.dark {
    fill: $ui-icon-dark;
  }

  &.gray {
    fill: $ui-icon-gray;
  }

  &.light {
    fill: $ui-bg-light;
  }

  &.red {
    fill: $brick;
  }

  &.brand {
    fill: $ui-icon-brand;
  }

  &.accent {
    fill: $ui-icon-accent;
  }

  &.white {
    fill: $ui-icon-white;
  }

  /* sizes */
  &.s8 {
    width: 8px;
    height: 8px;
  }

  &.s10 {
    width: 10px;
    height: 10px;
  }

  &.s12 {
    width: 12px;
    height: 12px;
  }

  &.s16 {
    width: 16px;
    height: 16px;
  }

  &.s18 {
    width: 18px;
    height: 18px;
  }

  &.s20 {
    width: 20px;
    height: 20px;
  }

  &.s24 {
    width: 24px;
    height: 24px;
  }

  &.s28 {
    width: 28px;
    height: 28px;
  }
}
