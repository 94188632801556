@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.modal-wrap {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .modal {
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: rgba(36, 43, 63, 0.6);
    overflow: hidden;
    overflow-y: auto;
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.3s ease-out 0s;

    .modal {
      opacity: 1;
    }
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-out 0s;

    .modal {
      opacity: 0;
    }
  }
}

/* loader */
.modal.loader {
  .loader-img {
    width: 42px;
    height: 42px;
  }
}

/* alert */
.modal.alert {
  justify-content: center;

  .alert-card {
    align-items: center;
    justify-content: center;
    max-width: 660px;
    min-width: 548px;
    padding: 24px 0 0;
    border-radius: 9px;
    background-color: $ui-bg-white;
    box-shadow: 0 0 19px $ui-shadow-table;

    .alert-card-title {
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 6px;
      padding: 12px 0 8px;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      background-color: $ui-bg-light;

      h3 {
        color: $ui-text-dark;
        font-size: 22px;
        font-weight: 700;
        text-align: center;
      }
    }

    .dialog-icon {
      width: 41px;
      height: 41px;
      margin-top: 12px;
      margin-bottom: 18px;
    }

    .dialog-text {
      padding: 0 20px;

      p {
        margin-bottom: 32px;
        font-size: 16px;
      }

      .dialog-text-item {
        .dialog-text-item-title {
          margin-bottom: 3px;
          color: $ui-text-dark;
          font-size: 16px;
          font-weight: 800;
          text-align: center;
        }

        .dialog-text-item-text {
          margin-bottom: 16px;
          color: $ui-text-dark;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
        }
      }
    }

    .alert-card-text {
      padding: 33px 56px;

      p {
        color: $ui-text-dark;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
      }
    }

    .alert-card-button {
      width: 256px;
      padding-bottom: 53px;
    }

    .dialog-buttons {
      grid-template-columns: 1fr 1fr;
      grid-gap: 36px;
      width: 100%;
      padding: 5px 56px 46px 56px;
    }
  }
}

@media all and (max-width: $mobile) {
  .modal.alert {
    padding: 26px;

    .alert-card {
      max-width: 100%;
      min-width: 0;
      width: 100%;

      .dialog-buttons {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 20px;
      }
    }
  }
}
