@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

p {
  margin-bottom: 19px;
  font-family: "Inter";
  font-weight: 400;

  strong {
    
  }

  &.light {
    color: $ui-text-light;
  }

  &.dark {
    color: $ui-text-dark;
  }

  &.f14 {
    font-size: 14px;

    &.adaptive {
      @include font-size($ui-font-14);
    }
  }

  &.f16 {
    font-size: 16px;

    &.adaptive {
      @include font-size($ui-font-16);
    }
  }

  &.f22 {
    font-size: 22px;

    &.adaptive {
      @include font-size($ui-font-22);
    }
  }
}
