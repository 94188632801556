@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.input-search {
  position: relative;
  width: 100%;
  margin-top: 15px;

  label {
    margin-bottom: 22px;
    color: $ui-text-search;
    font-size: 16px;
    font-weight: 800;
  }

  .input-search-wrap {
    position: relative;
    width: 100%;

    .input-text {
      width: 100%;
      height: 44px;
      padding: 0 52px 0 24px;
      border: 1px solid $ui-line-gray;
      border-radius: 5px;
      background-color: $ui-bg-white;
      color: $ui-text-dark;
      font-size: 14px;

      &::-moz-placeholder {
        color: $ui-text-gray;
      }
      &::-webkit-input-placeholder {
        color: $ui-text-gray;
      }
      &:-ms-input-placeholder {
        color: $ui-text-gray;
      }
      &::-ms-input-placeholder {
        color: $ui-text-gray;
      }
      &::placeholder {
        color: $ui-text-gray;
      }

      &:focus {
        border-color: $ui-line-dark;
      }
    }

    .input-search-icon {
      position: absolute;
      top: 13px;
      right: 17px;
      z-index: 2;
      width: 19px;
      height: 19px;
      fill: $gray;
    }

    &.small {
      .input-text {
        width: auto;
        height: 32px;
        padding: 0 16px;
        border-radius: 16px;
        font-size: 10px;
      }

      &.x-small {
        .input-text {
          height: 20px;
          border-radius: 4px;
        }

        .input-search-icon {
          top: 4px;
          right: 8px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

@media all and (max-width: $ipad) {
  .input-search {
    margin-top: 0;

    label {
      display: none;
    }

    .input-search-wrap {
      max-width: 100%;
    }
  }
}
