@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.modal-import {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 32px 20px;

  .modal-import-wrap {
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 640px;
    padding: 60px 20px 20px;
    border-radius: 8px;
    background-color: $ui-bg-white;

    & > h3 {
      position: absolute;
      top: 24px;
      left: 20px;
    }

    hr {
      width: 100%;
      height: 0;
      border-bottom: 1px solid $ui-line-table;
      border-style: dashed;
    }

    .highlight {
      border: 3px solid  $ui-line-table;
    }

    .import-form {
      flex-direction: column;
      width: 100%;

      .import-form-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 120px;
        grid-gap: 20px;
        align-items: center;
      }

      .import-form-add {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 20px 0;
        padding: 42px 20px;
        border-radius: 12px;
        background-color: $gray6;

        .import-form-add-wrap {
          align-items: center;
          justify-content: center;
        }

        & > p {
          color: $ui-text-dark;
          font-weight: 500;
          text-transform: uppercase;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    /**
    * close button
    */
    .modal-deal-info-close {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 2;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      border-radius: 7px;
      background-color: $ui-btn-white;
      transition: all 0.35s ease-out 0s;

      .modal-deal-info-close-icon {
        width: 20px;
        height: 20px;
        fill: $red;
        transition: all 0.35s ease-out 0s;
      }

      &:hover {
        background-color: $ui-btn-dark;
        transition: all 0.35s ease-out 0s;

        .modal-deal-info-close-icon {
          fill: $ui-icon-white;
          transition: all 0.35s ease-out 0s;
        }
      }
    }

    /* modal left */
    .modal-deal-about {
      width: 400px;
      height: 100%;
      padding: 12px;

      &.add {
        width: auto;
        flex-grow: 1;
        flex-shrink: 1;
      }

      .modal-deal-about-title {
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
        padding-left: 8px;

        .modal-deal-about-title-wrap {
          flex-direction: column;
          flex-grow: 1;
          flex-shrink: 1;

          h3 {
            font-weight: 500;
          }

          p {
            color: $ui-text-dark-gray;
          }
        }

        .modal-deal-about-buttons {
          flex-direction: row;
          flex-grow: 0;
          flex-shrink: 0;

          .modal-deal-about-button {
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            margin-left: 6px;
            border-radius: 8px;

            .modal-deal-about-button-icon {
              width: 18px;
              height: 18px;
            }

            &.teal {
              .modal-deal-about-button-icon {
                fill: $teal;
              }
            }

            &.violet {
              .modal-deal-about-button-icon {
                fill: $purple;
              }
            }

            &.primary {
              .modal-deal-about-button-icon {
                fill: $indigo;
              }
            }

            &.amber {
              .modal-deal-about-button-icon {
                fill: $amber;
              }
            }

            &.active {
              &.teal {
                background-color: $teal;

                .modal-deal-about-button-icon {
                  fill: $ui-icon-white;
                }
              }

              &.violet {
                background-color: $purple;

                .modal-deal-about-button-icon {
                  fill: $ui-icon-white;
                }
              }

              &.primary {
                background-color: $indigo;

                .modal-deal-about-button-icon {
                  fill: $ui-icon-white;
                }
              }

              &.amber {
                background-color: $amber;

                .modal-deal-about-button-icon {
                  fill: $ui-icon-white;
                }
              }
            }
          }
        }
      }

      /*
      ** notes
      */
      .modal-deal-notes {
        flex-grow: 1;
        flex-shrink: 1;
        width: 100%;
        padding-top: 16px;
        padding-left: 8px;
        overflow: hidden;
        overflow-y: auto;

        .modal-deal-notes-list-wrap {
          flex-grow: 1;
          flex-shrink: 1;
          width: 100%;
          overflow: hidden;
          overflow-y: auto;

          .modal-deal-notes-list {
            width: 100%;
          }
        }

        .modal-deal-notes-form {
          flex-grow: 0;
          flex-shrink: 0;
          width: 100%;
          height: 48px;
          padding-top: 8px;
          border-top: 1px solid $ui-line-gray;
        }
      }
    }

    .modal-deal-info-wrap {
      flex-grow: 1;
      flex-shrink: 1;
      height: 100%;
      border-left: 1px solid $ui-line-gray;

      .modal-deal-info {
        grid-gap: 8px;
        width: 100%;
        height: 100%;
        padding: 20px;
        overflow: hidden;

        h3 {
          flex-grow: 0;
          flex-shrink: 0;
          margin: 4px 0;
          font-weight: 500;
        }

        .modal-deal-info-top {
          width: 100%;
          padding-right: 48px;
        }

        p {
          color: $ui-text-gray;
          font-size: 12px;
        }

        .modal-deal-info-form {
          flex-grow: 1;
          flex-shrink: 1;
          grid-gap: 8px;
          width: 100%;
          margin: 20px 0;
          padding: 20px 0;
          border-top: 1px solid $ui-line-gray;
          border-bottom: 1px solid $ui-line-gray;
          overflow: hidden;
          overflow-y: auto;

          .modal-deal-add-switch {
            padding: 8px;
          }

          .modal-deal-info-workers {
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
            margin-top: 16px;
            border-top: 1px solid $ui-line-gray;
            overflow: hidden;
            overflow-y: auto;
          }

          .modal-deal-info-static {
            flex-direction: column;
            width: 100%;
          }

          .modal-deal-button-call {
            padding: 4px 8px;
            border-radius: 4px;
            color: $ui-text-white;

            &.green {
              background-color: $green;
            }

            &.primary {
              background-color: $indigo;
            }
          }
        }
      }
    }
  }
}
