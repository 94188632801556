@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.input-wrap {
  position: relative;
  width: 100%;
  margin-bottom: 8px;

  label {
    margin-bottom: 4px;
    color: $ui-text-dark;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.8;
  }

  .search-dropdown {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: $ui-bg-white;
    color: $ui-text-dark;
    font-size: 14px;

    &.disabled {
      border-color: $ui-bg-light;
      background-color: $ui-bg-light;
    }

    &.small {
      height: 45px;
      padding: 0 18px;
      border-radius: 3px;
    }

    &::-moz-placeholder {
      color: $ui-text-gray;
    }
    &::-webkit-input-placeholder {
      color: $ui-text-gray;
    }
    &:-ms-input-placeholder {
      color: $ui-text-gray;
    }
    &::-ms-input-placeholder {
      color: $ui-text-gray;
    }
    &::placeholder {
      color: $ui-text-gray;
    }

    &:focus {
      border-color: $ui-line-dark;
    }
  }

  .input-text {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    border: 1px solid $ui-line-gray;
    border-radius: 5px;
    background-color: $ui-bg-white;
    color: $ui-text-dark;
    font-size: 14px;

    &.disabled {
      border-color: $ui-bg-light;
      background-color: $ui-bg-light;
    }

    &.small {
      height: 45px;
      padding: 0 18px;
      border-radius: 3px;
    }

    &::-moz-placeholder {
      color: $ui-text-gray;
    }
    &::-webkit-input-placeholder {
      color: $ui-text-gray;
    }
    &:-ms-input-placeholder {
      color: $ui-text-gray;
    }
    &::-ms-input-placeholder {
      color: $ui-text-gray;
    }
    &::placeholder {
      color: $ui-text-gray;
    }

    &:focus {
      border-color: $ui-line-dark;
    }
  }

  .input-text-info-wrap {
    position: absolute;
    right: 0;
    top: 0;
    align-items: flex-end;
    justify-content: center;
    width: 100px;
    height: 100%;
    padding: 2px 13px 0 13px;

    .input-text-info {
      color: $ui-text-dark;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .input-text-error {
    color: $ui-text-error;
    font-size: 14px;
    font-weight: 400;
  }
}

.area-wrap {
  width: 100%;

  .setting-text-area {
    background-color: #fff;
    border: 1px solid $ui-line-gray;
    border-radius: 5px;
    width: 100%;
    max-width: 359px;
    max-height: 360px;
    min-height: 200px;
    padding: 4px 0;
    resize: vertical;
  }
}
