@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.check-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 9px 0;

  &-icon {
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
    border: 1px solid $ui-line-gray;
    border-radius: 2px;
    margin-right: 8px;
    overflow: hidden;
    transition: all 0.35s ease-out 0s;

    &-check {
      width: 8px;
      height: 6px;
    }
  }

  &-icon.active {
    border-color: $ui-line-dark;
    background-color: $ui-line-dark;
    transition: color 0.35s ease-out 0s;

    .check-item-icon-check {
      opacity: 1;
      transition: color 0.35s ease-out 0s;
    }
  }

  span {
    font-size: 14px;
    font-weight: 400;
  }

  &.small {
    &.x-small {
      padding: 6px 0;

      span {
        font-size: 10px;
      }
    }
  }
}
