@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.card-avatar {
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: $ui-bg-brand;

  span {
    color: $ui-text-white;
    font-size: 16px;
    font-weight: 600;
  }
}
