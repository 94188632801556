@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

h2 {
  padding: 0 1rem;
  color: $ui-text-light;
  @include font-size($ui-font-40);
  font-style: normal;
  font-weight: 700;

  &.f20 {
    font-size: 20px;
  }

  &.f23 {
    font-size: 23px;
  }

  span {
    color: $ui-text-brand;
  }
}
