@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

h3 {
  font-family: "Inter";
  font-weight: 700;

  &.brand {
    color: $ui-text-brand;
  }

  &.blue {
    color: $blue;
  }

  &.text-22 {
    font-size: 22px;

    &.adaptive {
      @include font-size($ui-font-22);
    }
  }

  &.text-32 {
    font-size: 32px;

    &.adaptive {
      @include font-size($ui-font-32);
    }
  }

  &.text-40 {
    font-size: 40px;

    &.adaptive {
      @include font-size($ui-font-40);
    }
  }
}
