@import "../../styles/colors.scss";

.paginator {
  .paginator-inner {
    flex-direction: row;
    max-width: 279px;
  }

  .paginator-button-wrap {
    flex-grow: 0;
    flex-shrink: 0;

    .paginator-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 24px;
      border: 1px solid $ui-line-gray;
      background-color: $ui-btn-white;
      font-size: 13px;
      font-weight: 500;
      

      .paginator-button-icon {
        width: 10px;
        height: 10px;
        fill: $ui-icon-gray;
      }

      &.next {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        
      }

      &.prev {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        fill: black;
      }

      &.disabled {
        opacity: 1;
      }
    }
  }

  .paginator-num-buttons {
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 8px;

    .paginator-num {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 24px;
      padding: 0 8px;
      color: $ui-text-dark;
      font-size: 14px;
    }

    .paginator-num.active {
      color: $ui-text-brand;
      font-weight: 800;
    }

    .paginator-spots {
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 24px;
    }
  }
}
