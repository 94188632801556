@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.table-card {
  border-bottom: 1px solid $ui-line-table;

  &:hover {
    background-color: $gray5;
  }

  .table-card-col {
    padding: 12px 8px;

    .table-card-col-inner {
      position: relative;
      flex-direction: row;
      align-items: center;
    }

    .table-card-title {
      display: none;
      margin-bottom: 3px;
      color: $ui-text-dark;
      font-size: 11px;
      font-weight: 700;

      &.active {
        border-bottom: 2px solid $blue;
      }
    }

    .table-card-sort {
      flex-grow: 0;
      flex-shrink: 0;
      width: 6px;
      height: 6px;
      margin-left: 8px;
      margin-bottom: 2px;
    }

    &.blue {
      & > span {
        color: $blue;
      }
    }

    &.red {
      & > span {
        color: $red;
      }
    }
  }

  &:last-of-type {
    border-bottom-width: 0;
  }

  &.title {
    cursor: pointer;

    .table-card-col {
      position: relative;

      .table-card-title {
        display: flex;
      }

      .table-sort-dropdown {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        padding: 12px 20px;
        border-radius: 4px;
        background-color: $ui-bg-gray6;
        box-shadow: 0 0 19px $ui-shadow-table;

        &.open {
          display: flex;
        }

        h4 {
          margin-bottom: 8px;
        }

        .table-sort-dropdown-sort {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 8px;
        }

        .table-sort-dropdown-color {
          display: grid;
          grid-template-columns: 60px 1fr;
          grid-gap: 8px;
          align-items: center;
          //margin-bottom: 24px;

          p {
            margin-bottom: 8px;
          }
        }

        .table-sort-dropdown-search {
          width: 100%;

          .table-sort-dropdown-search-scroll {
            width: 100%;
            max-height: 112px;
            margin: 8px 0 12px 0;
            padding: 6px 12px;
            border-radius: 4px;
            background-color: $ui-bg-white;
            overflow: hidden;
            overflow-y: scroll;
          }
        }
        
        .table-sort-dropdown-btns {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 8px;
        }
      }
    }
  }

  &.colored {
    border-top: 2px solid rgba($gray2, 0.35);
    border-bottom: 2px solid rgba($gray2, 0.35);
    background-color: rgba($blue, 0.25);

    &:first-child {
      border-top-width: 0;
      background-color: $ui-bg-light !important;
    }

    .table-card-col {
      vertical-align: middle;
      padding: 20px 20px 30px 20px;

      .table-card-title {
        margin-top: 0;
        font-size: 18px;
        font-weight: 400;
      }

      span {
        font-size: 18px;
      }
    }

    &.blue {
      background-color: rgba($blue, 0.2);
    }

    &.green {
      background-color: rgba($green, 0.4);
    }

    &.yellow {
      background-color: rgba($yellow, 0.3);
    }

    &.violet {
      background-color: rgba($purple, 0.3);
    }

    &.white {
      background-color: rgba($white, 1);
    }
  }
}

.blueBg {
  background-color: rgba(110, 165, 227, 0.4) !important;
}

@media all and (max-width: $ipad) {
  .table-card.static {
    .table-card-col {
      padding: 1px;
    }

    .table-card-title {
      font-size: 10px !important;
    }

    .table-card-col span {
      font-size: 10px !important;
    }
  }

  .table-card:not(.static) {
    display: grid;
    align-items: flex-start;
    padding: 8px 18px;
    border-bottom-width: 0;

    &.grid-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.grid-4 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &.grid-5 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &.grid-6 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &.grid-7 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-8 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-9 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-10 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-11 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-12 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-13 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-14 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-15 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-16 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.title {
      display: none;

      .table-card-col {
        display: none;
      }
    }

    .table-card-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 6px 20px;

      .table-card-title {
        display: flex;
      }
    }

    &:nth-child(2n) {
      background-color: $ui-bg-light;
    }
  }
}

@media all and (max-width: $mobile) {
  .table-card:not(.static) {
    padding: 8px 0;

    .table-card-title {
      margin-bottom: 2px;
    }

    &.grid-3 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &.grid-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &.grid-5 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-7 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-8 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-9 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-10 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-11 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }

    &.grid-12 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }

    &.grid-13 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(7, 1fr);
    }

    &.grid-14 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(7, 1fr);
    }

    &.grid-15 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(8, 1fr);
    }

    &.grid-16 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(8, 1fr);
    }
  }
}

@media all and (max-width: 380px) {
  .table-card:not(.static) {
    padding: 6px 0;

    &.grid-14 {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(14, 1fr);
    }

    &.grid-15 {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(15, 1fr);
    }

    &.grid-16 {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(16, 1fr);
    }

    .table-card-col {
      padding: 0 16px;
    }
  }
}
